@use './scss/' as *;


@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~@flaticon/flaticon-uicons/css/all/all";

@font-face {
    font-family: 'cybox';
    // src:  url('../src/assets/font/icon/Furore.otf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
html {
    margin-right: 0 !important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.breadcrumb-area {
    position: relative;
    padding: 110px 0 75px;
    background-position: center;
    background-size: cover;
    min-height: 561px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.breadcrumb-area::before, .breadcrumb-area::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background-color: #b4ab09;
    height: 50px;
}
@media (max-width: 1500px) {
    .breadcrumb-area {
        min-height: 480px;
    }
}

@media (max-width: 1199.98px) {
    .breadcrumb-area {
        min-height: 400px;
    }
}

@media (max-width: 767.98px) {
    .breadcrumb-area {
        min-height: 310px;
        padding: 120px 0 75px;
    }
}

.breadcrumb-area::before,
.breadcrumb-area::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background-color: #b4ab09;
    height: 50px;
}

@media (max-width: 1500px) {

    .breadcrumb-area::before,
    .breadcrumb-area::after {
        height: 40px;
    }
}

@media (max-width: 1199.98px) {

    .breadcrumb-area::before,
    .breadcrumb-area::after {
        height: 30px;
    }
}

@media (max-width: 767.98px) {

    .breadcrumb-area::before,
    .breadcrumb-area::after {
        height: 20px;
    }
}

.breadcrumb-area::after {
    left: auto;
    right: 0;
    -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.breadcrumb__wrapper {
    position: relative;
    padding: 0 80px;
}

@media (max-width: 1199.98px) {
    .breadcrumb__wrapper {
        padding: 0 0;
    }
}

@media (max-width: 991.98px) {
    .breadcrumb__content {
        text-align: center;
    }
}

.breadcrumb__content .title {
    font-size: 60px;
    font-weight: var(--tg-fw-extra-bold);
    letter-spacing: 3px;
    line-height: 1;
    margin: 0 0;
}

@media (max-width: 1500px) {
    .breadcrumb__content .title {
        font-size: 50px;
        letter-spacing: 2px;
    }
}

@media (max-width: 767.98px) {
    .breadcrumb__content .title {
        font-size: 43px;
    }
}

.breadcrumb__content .breadcrumb {
    margin: 12px 0 0;
}

@media (max-width: 991.98px) {
    .breadcrumb__content .breadcrumb {
        justify-content: center;
    }
}

.breadcrumb__content .breadcrumb .breadcrumb-item {
    text-transform: uppercase;
    font-weight: var(--tg-fw-bold);
    font-size: 14px;
    letter-spacing: 2px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.breadcrumb__content .breadcrumb .breadcrumb-item::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #b4ab09;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    margin: 0 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.breadcrumb__content .breadcrumb .breadcrumb-item:hover::after {
    background: var(--tg-theme-secondary);
}

.breadcrumb__content .breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding: 0;
}

.breadcrumb__content .breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb__content .breadcrumb .breadcrumb-item+.breadcrumb-item::after {
    display: none;
}

.breadcrumb__content .breadcrumb .breadcrumb-item.active {
    color: var(--tg-common-color-white);
}

.breadcrumb__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
}

@media (max-width: 1500px) {
    .breadcrumb__img {
        right: 60px;
        top: 60%;
    }
}

.breadcrumb__img img {
    max-height: 412px;
    max-width: 402px;
}

@media (max-width: 1500px) {
    .breadcrumb__img img {
        max-height: 320px;
        max-width: 310px;
    }
}

@media (max-width: 1199.98px) {
    .breadcrumb__img img {
        max-height: 260px;
        max-width: 255px;
    }
}

.breadcrumb__img:hover img {
    -webkit-animation: breadcrumbShake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: breadcrumbShake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.breadcrumb__hide-img .breadcrumb__wrapper {
    padding: 0 0;
}

.breadcrumb__hide-img .breadcrumb__content {
    text-align: center;
}

.breadcrumb__hide-img .breadcrumb {
    justify-content: center;
}

body {
    font-family: "Chakra Petch", sans-serif !important;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: $txt-color;
    background-color: $bgr-body;
    position: relative;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
    transform: scale(1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    color: $white;
}
h1,
.h1 {
    font-size: 80px;
    line-height: 1.2;
}
h2,
.h2 {
    font-size: 64px;
    line-height: 1.25;
}
h3,
.h3 {
    font-size: 56px;
    line-height: 1.2;
}
h4,
.h4 {
    font-size: 44px;
    line-height: 1.25;
}
h5,
.h5 {
    font-size: 36px;
    line-height: 1.2;
}
h6,
.h6 {
    font-size: 30px;
    line-height: 1.33;
}

.h7,
.h8 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    color: $white;
}

.h7 {
    font-size: 24px;
    line-height: 1.33;  
    &.st {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
    }
}

.h8 {
    font-size: 20px;
    line-height: 1.3;  
    &.st {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
    }
}

.container {
    max-width: 1440px;
}

.z-index {
    z-index: 999;
}

#wrapper {
    width: $w100;
    height: $w100;
    overflow: hidden;
    position: relative;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}
ul, li {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
    transition: all .3s;
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: $color-hover;
        transition: all .3s;
    }
}

.center {
    text-align: center;
}

.container {
    width: 100%;
    margin: auto;
}
.container-fluid,
.container {
    padding-left: 15px;
    padding-right: 15px;
}

.row > * {
    padding-right: 15px;
    padding-left: 15px;
}

.wrap-fx-30 {
    @include flex2;
    margin-left: -30px;
    .icon-box {
        width: calc(33.3333% - 30px);
        margin-left: 30px;
    }
}
.no-pd-top {
    padding-top: 0;
}

.m-b17 {
    margin-bottom: 17px;
}

.p-b42 {
    padding-bottom: 42px;
}
.m-b40 {
    margin-bottom: 40px;
}
.m-r-40 {
    margin-right: -40px;
}

.p-l150 {
    padding-left: 150px;
}

.mt-30 {
    margin-top: 30px;
}
.mt-33 {
    margin-top: 33px;
}


.pl-70 {
    padding-left: 70px;
    @include desktop-width {
        padding-left: 0;
    }
}

.mt-48 {
    margin-top: 48px;
}

.mt--160 {
    margin-top: -160px !important;
}

.ml30-fx {
    margin-left: -30px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-39 {
    margin-bottom: 39px;
}

.mb-51 {
    margin-bottom: 51px;
}

.p-r60 {
    padding-right: 60%;
    @include tablet {
        padding-right: 0;
    }
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-49 {
    margin-bottom: 49px;
}
.mb-59 {
    margin-bottom: 59px;
}

.mb-61 {
    margin-bottom: 61px;
}
.mb-42 {
    margin-bottom: 42px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-58 {
    margin-bottom: 58px;
}

.mb-60 {
    margin-bottom: 60px;
}

.m-b6 {
    margin-bottom: 6px;
}

.m-t12 {
    margin-top: 12px;
}
.mt-48 {
    margin-top: 48px;
}

.m-b16 {
    margin-bottom: 16px;
}
.m-t16 {
    margin-top: 16px;
}
.mb-40 {
    margin-bottom: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.pl-r-0 {
    padding-left: 0 ;
    padding-right: 0 ;
}

.p-b23 {
    padding-bottom: 23px;
}

.boder-bt-st1 {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.txt-tranf-cap {
    text-transform: capitalize;
}

.m-t60 {
    margin-top: 60px;
}

.m-b50 {
    margin-bottom: 50px;
}

.m-b48 {
    margin-bottom: 48px;
}


.m-t40 {
    margin-top: 40px;
}

.pd20 {
    padding: 0 20%;
}

.pd22 {
    padding: 0 22%;
}

.mb-51 {
    margin-bottom: 51px;
}

.pd30 {
    padding: 0 30%;

    @include mobile {
        padding: 0;
    }
}

.bg-st1 {
    background: $bg-3 !important;
}

.bg-st2 {
    background: $bg-main !important;
}

.wrap-fx {
    @include flex2;
    flex-wrap: wrap;
}

.wrap-fx2 {
    @include flex(center,center);
    position: relative;
}

// form //
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"], 
input[type="tel"], 
input[type="color"] {
    border: 1px solid rgba(255,255,255,0.2);
    font-family: $font-main2;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
    border-radius: 0;
    padding: 13px 15px 13px 27px;
    width: 100%;
    background: transparent;
    color: $color-main2;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder, 
input[type="tel"]::placeholder, 
input[type="color"]::placeholder {
    font-family: $font-main2;
    color: $color-main2;
}




/* PreLoad
-------------------------------------------------------------- */
.preload {
    overflow: hidden;
} 

.preload-container {
    position  : relative;
    width     : 100%;
    height    : 100%;
    background: #fff;
    position  : fixed;
    top       : 0;
    bottom    : 0;
    right     : 0;
    left      : 0;
    z-index   : 99999999999;
    display   : block;
    overflow: hidden;
}

.preload-logo {
	position           : absolute;
	top                : 50%;
	left               : 50%;
	width              : 50px;
	height             : 50px;
	z-index            : 100;
	margin             : -30px 0 0 -30px;
	background: #ffffff;
	border-radius: 50%;
	background-image   : url('./assets/images/logo.png');
	background-repeat  : no-repeat;
	background-position: center center;
	animation: 
	preload 1s linear infinite alternate;
	-webkit-animation-name:bounceIn; 
	animation-name:bounceIn; 
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
} 

.tf-section {  
    position: relative;
    padding: 80px 0;
    margin-bottom: 0 !important;
}


.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}